import React, { Component } from 'react'
import Link from '../utils/link'

class Header extends Component {

  state = {
    careers: true
  }

  render() {

    let { careers } = this.state

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <ul>
              <li>
                <Link to='mailto:info@id-group.net.au'>
                  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 10.4508V1.78507C0.0124994 1.7513 0.0231238 1.71625 0.0312482 1.68121C0.196233 0.721605 1.01557 0.00351988 1.97244 0.0028674C5.99034 -0.000955799 10.0084 -0.000955799 14.0269 0.0028674C14.9381 0.0028674 15.7269 0.655346 15.9413 1.55759C15.9594 1.63406 15.9813 1.70924 16 1.78506L15.9994 10.4508C15.9881 10.4737 15.9788 10.4979 15.9719 10.5222C15.8231 11.3683 15.2463 12.0195 14.427 12.1764C14.0432 12.2503 13.6401 12.2318 13.2458 12.2324C9.50401 12.2363 5.76178 12.2363 2.01938 12.2324C1.09942 12.2324 0.32698 11.6392 0.0769108 10.7542C0.0487872 10.656 0.0256635 10.5528 3.90382e-05 10.4509L0 10.4508ZM5.44343 5.96876C5.19345 6.22045 4.9572 6.45812 4.72472 6.69898L2.17859 9.2872C2.02485 9.44267 1.95361 9.61982 2.0186 9.83391C2.13422 10.2232 2.58107 10.3169 2.88105 10.0136C3.93976 8.93931 4.996 7.86189 6.05147 6.78188C6.09022 6.73536 6.12584 6.68503 6.15709 6.63278C6.24459 6.71434 6.30271 6.76531 6.35958 6.81756L7.63202 7.99636C7.87388 8.21938 8.12636 8.21683 8.36949 7.99317C8.80947 7.58728 9.24945 7.18012 9.68943 6.77296C9.72943 6.73472 9.7738 6.69713 9.82317 6.65062C10.2213 7.05714 10.6106 7.45602 10.9994 7.85362L13.1193 10.015C13.263 10.1621 13.4274 10.231 13.628 10.1762C14.0167 10.0691 14.128 9.60779 13.8336 9.30578C13.1255 8.57875 12.4149 7.85364 11.7025 7.13172C11.3238 6.74558 10.9426 6.36072 10.5576 5.96822C10.6076 5.91979 10.6488 5.87965 10.6907 5.84078L12.2388 4.40711L13.8224 2.93903C13.9749 2.79757 14.0355 2.62426 13.9849 2.42227V2.42163C13.9456 2.23939 13.8068 2.09603 13.6287 2.05461C13.4287 2.00045 13.2668 2.075 13.1175 2.21391C11.4556 3.75717 9.79137 5.2998 8.12457 6.84047C8.08457 6.8787 8.04145 6.91438 7.99707 6.95389L5.61526 4.74802C4.69842 3.9012 3.78158 3.05239 2.86418 2.20303C2.71919 2.06858 2.55794 2.00422 2.36421 2.05903L2.36358 2.05966C2.18733 2.10299 2.04985 2.24508 2.01048 2.42668C1.96235 2.63058 2.0261 2.80199 2.1786 2.94281C2.8342 3.54876 3.48979 4.15601 4.14411 4.76388L5.44343 5.96876Z" fill="white"/>
                  </svg>
                  <span>Email Us</span>
                </Link>
              </li>
              <li>
                <Link to='tel:+61395601812'>
                  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8875 11.4282C11.5606 12.4288 10.4344 13.5275 9.73505 13.5275C9.68442 13.5288 9.64255 13.5225 9.60005 13.5125C7.07637 12.8057 5.11253 11.5289 3.23125 9.37189C1.53125 7.42181 0.489328 5.26821 0.0475676 2.78757C-0.121184 1.78007 1.25194 0.731254 2.26069 0.515094C2.39194 0.486969 2.5157 0.472595 2.62882 0.472595C2.99006 0.472595 3.22006 0.620096 3.29256 0.900099L4.25318 3.51386C4.40318 3.82448 4.19881 4.06949 3.85256 4.44386C3.39318 4.94074 2.82131 5.55949 3.67318 6.5357L6.04694 9.25698C6.89507 10.2289 7.58131 9.75261 8.13254 9.36948C8.54818 9.08073 8.81754 8.91322 9.10629 9.10761L11.6295 10.4557C11.8595 10.5514 12.0932 10.7976 11.8876 11.4282L11.8875 11.4282Z" fill="white"/>
                  </svg>
                  <span>(03) 9560 1812</span>
                </Link>
              </li>
            </ul>
            <Link className='header__link' to='/'>
            {/* <Link className='header__link' to='/careers/'> */}
              Not Hiring 
            </Link>         
          </div>
        </header>
      </>
    )
  }
}

export default Header